import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import SectionWrapper from '@stories/Components/Global/SectionWrapper/SectionWrapper';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { m } from 'framer-motion';
import styled from 'styled-components';
const MotionWrapper = styled(m.div).attrs(({ inView }) => ({
    initial: 'hidden',
    animate: inView ? 'visible' : 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            y: 25,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    },
    transition: {
        duration: 0.7,
    },
})) ``;
const Container = styled(SectionWrapper) `
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 35px;
  gap: 20px;
  max-width: 600px;

  ${() => ParagraphStyles.Paragraph} {
    text-align: center;
  }
`;
const Title = styled.div `
  color: ${brand.primary.alt};

  font-size: 22px;
  line-height: 25px;
  @media ${from(Device.Tablet)} {
    margin-bottom: 30px;
    font-size: 25px;
  }

  @media ${from(Device.Desktop)} {
    margin-bottom: 20px;
    font-size: 28px;
  }
`;
const Text = styled.div `
  ${fonts.DaxPro.Regular};
  font-size: 18px;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 16px;
`;
export const CenterTextStyles = {
    Container,
    Title,
    Text,
    MotionWrapper,
};
