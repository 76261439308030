import styled, { css } from 'styled-components';
const Container = styled.section `
  --section-margin: 64px;

  margin-top: var(--section-margin);
  margin-bottom: var(--section-margin);

  ${({ flush }) => flush === 'top' &&
    css `
      margin-top: 0;
    `}

  ${({ flush }) => flush === 'bottom' &&
    css `
      margin-bottom: 0;
    `}

  ${({ flush }) => flush === 'both' &&
    css `
      margin-top: 0;
      margin-bottom: 0;
    `}
`;
export default {
    Container,
};
