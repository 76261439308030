import { HydrateOption } from '@core/enums';
import { withMotion } from '@hoc/withMotion';
import withWidget from '@hoc/withWidget';
import { useInView } from '@hooks/useInView';
import React from 'react';
import { CenterTextStyles as S } from './CenterText.styles';
const CenterText = ({ title, text }) => {
    const [ref, inView] = useInView({ threshold: 0.35 });
    return (React.createElement(S.MotionWrapper, { ref: ref, inView: inView },
        React.createElement(S.Container, null,
            React.createElement(S.Title, null, title),
            React.createElement(S.Text, { dangerouslySetInnerHTML: { __html: text } }))));
};
export default withWidget(withMotion(CenterText), 'CenterText', { hydrate: HydrateOption.InView });
