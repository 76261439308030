import React from 'react';
import SiteWide from '../SiteWide/SiteWide';
import s from './SectionWrapper.styles';
const SectionWrapper = React.forwardRef(({ children, className, fullWidth, skinnyWidth, ...otherProps }, ref) => {
    if (fullWidth) {
        return (React.createElement(s.Container, { ref: ref, className: className, ...otherProps }, children));
    }
    if (skinnyWidth) {
        return (React.createElement(s.Container, { ref: ref, className: className, ...otherProps },
            React.createElement(SiteWide, { isThin: true }, children)));
    }
    return (React.createElement(s.Container, { ref: ref, className: className, ...otherProps },
        React.createElement(SiteWide, null, children)));
});
SectionWrapper.displayName = 'SectionWrapper';
export default SectionWrapper;
